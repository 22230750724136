import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import Banner from 'components/Banner'
import Breadcrumb from 'components/Breadcrumb'
import IntroText from 'components/IntroText'
import Layout from 'components/Layout'
import NewsLink from 'components/NewsLink'
import Seo from 'components/Seo'
import Social from 'components/Social'
import * as styles from './news.module.scss'

import { tr } from 'js/translations.js'

const Page = ({ data }) => {
  const [activeCat, setActiveCat] = useState('')
  const [activePosts, setActivePosts] = useState([])

  const locale = process.env.GATSBY_LOCALE
  const page = data['page'].nodes[0]
  const posts = data['posts'].nodes
  const categories = data['categories'].nodes
  const breadcrumb = [
    {
      text: tr('HOME', locale),
      link: '/',
    },
    {
      text: page.title,
    },
  ]
  const introText = renderRichText(page.introText)

  const updateActivePosts = (newSlug) => {
    if (newSlug === activeCat) return
    setActiveCat(newSlug)
    let newActivePosts
    if (newSlug === 'all') {
      newActivePosts = posts
    } else {
      newActivePosts = posts.filter((post) => post.category?.slug === newSlug)
    }

    newActivePosts =
      locale === 'en'
        ? newActivePosts.filter((post) => post.publishEn)
        : newActivePosts.filter((post) => post.publishZh)

    setActivePosts([])
    setTimeout(() => setActivePosts(newActivePosts), 1)
  }

  useEffect(() => {
    updateActivePosts('all')
  }, [])

  const allCategory = [{ slug: 'all', title: tr('ALL_NEWS', locale) }]
  const categoriesPlusAll = allCategory.concat(categories)

  return (
    <Layout>
      <div>
        <Seo page={page} locale={locale} />
        <Banner image={page.banner} locale={locale} />
        <Breadcrumb items={breadcrumb} />

        <div className={styles.main}>
          <div className='wrapper pad-sides'>
            <div className='inner'>
              <div className={styles.introWrap}>
                <IntroText content={introText} addClass='news' />
                <div className={styles.socialWrap}>
                  <Social />
                </div>
              </div>
              <div className={styles.filters}>
                {categoriesPlusAll.map((category, i) => (
                  <Filter
                    content={category}
                    activeCat={activeCat}
                    updateActivePosts={updateActivePosts}
                    key={i}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className={styles.posts}>
            {activePosts.filter((post) => post.pinToTop).length > 0 && (
              <div className={`${styles.postGroup} ${styles.pinned}`}>
                <div className='wrapper pad-sides no-bg'>
                  <div className='inner'>
                    {activePosts
                      .filter((post) => post.pinToTop)
                      .map((post, i) => (
                        <NewsLink
                          key={i}
                          post={post}
                          isPinned={true}
                          locale={locale}
                        />
                      ))}
                  </div>
                </div>
              </div>
            )}
            <div className={`${styles.postGroup} ${styles.main}`}>
              <div className='wrapper pad-sides'>
                <div className='inner'>
                  {activePosts
                    .filter((post) => !post.pinToTop)
                    .map((post, i) => (
                      <NewsLink key={i} post={post} locale={locale} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const Filter = ({ content, activeCat, updateActivePosts }) => {
  const { title, slug } = content
  const activeClass = activeCat === slug ? styles.active : ''
  return (
    <h4
      className={`${styles.filter} ${activeClass}`}
      onClick={() => updateActivePosts(slug)}
    >
      {title}
    </h4>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    page: allContentfulNewsPage {
      nodes {
        title
        banner {
          ...GatsbyImage
        }
        introText {
          raw
        }
        seo {
          ...SeoFields
        }
      }
    }
    posts: allContentfulNewsPost(sort: { fields: postDate, order: DESC }) {
      nodes {
        publishZh
        publishEn
        slug
        title
        postDate(formatString: "YYYY-M-DD")
        pinToTop
        category {
          slug
          title
        }
        imageThumb {
          localFile {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1.6
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        excerpt {
          excerpt
        }
      }
    }
    categories: allContentfulNewsPostCategory(
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        slug
        title
      }
    }
  }
`
